import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { PermanentSidePanel } from '../SidePanel/PermanentSidePanel';
import { SidePanel } from '../SidePanel/SidePanel';
import { PermanentStackPanel } from '../StackPanel/PermanentStackPanel';
import { StackPanel } from '../StackPanel/StackPanel';
import { MainPanel, PanelContainer } from '../index';
import { SidePanelDemo } from './helpers/SidePanelDemo';
import { StackPanelDemo } from './helpers/StackPanelDemo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Panels" title="Panels" mdxType="PageTitle" />
    <h2 {...{
      "id": "sidepanel"
    }}>{`SidePanel`}</h2>
    <h3 {...{
      "id": "permanent-right-side-panel"
    }}>{`Permanent right side panel`}</h3>
    <Playground __position={1} __code={'<PanelContainer orientation=\"vertical\">\n  <MainPanel\n    style={{\n      background:\n        \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n      color: \'#fff\',\n    }}\n  >\n    Main panel\n  </MainPanel>\n  <PermanentSidePanel position=\"right\" width={200}>\n    Side panel\n  </PermanentSidePanel>\n</PanelContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      margin: '-40px',
      height: '200px'
    }} mdxType="Playground">
  <PanelContainer orientation="vertical" mdxType="PanelContainer">
    <MainPanel style={{
          background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
          color: '#fff'
        }} mdxType="MainPanel">
      Main panel
    </MainPanel>
    <PermanentSidePanel position="right" width={200} mdxType="PermanentSidePanel">
      Side panel
    </PermanentSidePanel>
  </PanelContainer>
    </Playground>
    <h3 {...{
      "id": "opened-right-side-panel"
    }}>{`Opened right side panel`}</h3>
    <Playground __position={2} __code={'<PanelContainer orientation=\"vertical\">\n  <MainPanel\n    style={{\n      background:\n        \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n      color: \'#fff\',\n    }}\n  >\n    Main panel\n  </MainPanel>\n  <SidePanel open position=\"right\" width={200}>\n    Side panel\n  </SidePanel>\n</PanelContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      margin: '-40px',
      height: '200px'
    }} mdxType="Playground">
  <PanelContainer orientation="vertical" mdxType="PanelContainer">
    <MainPanel style={{
          background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
          color: '#fff'
        }} mdxType="MainPanel">
      Main panel
    </MainPanel>
    <SidePanel open position="right" width={200} mdxType="SidePanel">
      Side panel
    </SidePanel>
  </PanelContainer>
    </Playground>
    <h3 {...{
      "id": "shiftable-side-panels"
    }}>{`Shiftable side panels`}</h3>
    <Playground __position={3} __code={'<SidePanelDemo style={{ height: \'200px\' }}>\n  {(isOpen, panelState, setPanelState) => (\n    <PanelContainer orientation=\"vertical\">\n      <SidePanel\n        onCloseFinish={() => setPanelState(\'closed\')}\n        onCloseStart={() => setPanelState(\'closing\')}\n        onOpenFinish={() => setPanelState(\'open\')}\n        onOpenStart={() => setPanelState(\'opening\')}\n        open={isOpen}\n        position=\"left\"\n        width={200}\n      >\n        Side panel\n      </SidePanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        <div>Main panel</div>\n        <div>Side panel state: {panelState}</div>\n      </MainPanel>\n      <SidePanel open={isOpen} position=\"right\" width={200}>\n        Side panel\n      </SidePanel>\n    </PanelContainer>\n  )}\n</SidePanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SidePanelDemo style={{
        height: '200px'
      }} mdxType="SidePanelDemo">
    {(isOpen, panelState, setPanelState) => <PanelContainer orientation="vertical" mdxType="PanelContainer">
        <SidePanel onCloseFinish={() => setPanelState('closed')} onCloseStart={() => setPanelState('closing')} onOpenFinish={() => setPanelState('open')} onOpenStart={() => setPanelState('opening')} open={isOpen} position="left" width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
        <MainPanel style={{
            background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          <div>Main panel</div>
          <div>Side panel state: {panelState}</div>
        </MainPanel>
        <SidePanel open={isOpen} position="right" width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
      </PanelContainer>}
  </SidePanelDemo>
    </Playground>
    <h3 {...{
      "id": "shiftable-fixed-side-panels"
    }}>{`Shiftable fixed side panels`}</h3>
    <Playground __position={4} __code={'<SidePanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"vertical\">\n      <SidePanel\n        fixed\n        open={isOpen}\n        position=\"left\"\n        style={{ background: \'#fff\' }}\n        width={200}\n      >\n        Side panel\n      </SidePanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <SidePanel\n        fixed\n        open={isOpen}\n        position=\"right\"\n        style={{ background: \'#fff\' }}\n        width={200}\n      >\n        Side panel\n      </SidePanel>\n    </PanelContainer>\n  )}\n</SidePanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SidePanelDemo style={{
        height: '200px'
      }} mdxType="SidePanelDemo">
    {isOpen => <PanelContainer orientation="vertical" mdxType="PanelContainer">
        <SidePanel fixed open={isOpen} position="left" style={{
            background: '#fff'
          }} width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
        <MainPanel style={{
            background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <SidePanel fixed open={isOpen} position="right" style={{
            background: '#fff'
          }} width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
      </PanelContainer>}
  </SidePanelDemo>
    </Playground>
    <h3 {...{
      "id": "squashable-side-panels"
    }}>{`Squashable side panels`}</h3>
    <Playground __position={5} __code={'<SidePanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"vertical\">\n      <SidePanel\n        fixed\n        open={isOpen}\n        position=\"left\"\n        style={{ background: \'#fff\' }}\n        transition=\"squashable\"\n        width={200}\n      >\n        Side panel\n      </SidePanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <SidePanel\n        fixed\n        open={isOpen}\n        position=\"right\"\n        style={{ background: \'#fff\' }}\n        transition=\"squashable\"\n        width={200}\n      >\n        Side panel\n      </SidePanel>\n    </PanelContainer>\n  )}\n</SidePanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SidePanelDemo style={{
        height: '200px'
      }} mdxType="SidePanelDemo">
    {isOpen => <PanelContainer orientation="vertical" mdxType="PanelContainer">
        <SidePanel fixed open={isOpen} position="left" style={{
            background: '#fff'
          }} transition="squashable" width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
        <MainPanel style={{
            background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <SidePanel fixed open={isOpen} position="right" style={{
            background: '#fff'
          }} transition="squashable" width={200} mdxType="SidePanel">
          Side panel
        </SidePanel>
      </PanelContainer>}
  </SidePanelDemo>
    </Playground>
    <h3 {...{
      "id": "side-panel-using-percentages"
    }}>{`Side panel using percentages`}</h3>
    <Playground __position={6} __code={'<SidePanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"vertical\">\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <SidePanel\n        fixed\n        open={isOpen}\n        position=\"right\"\n        style={{ background: \'#fff\' }}\n        unit=\"percent\"\n        width={100}\n      >\n        Side panel\n      </SidePanel>\n    </PanelContainer>\n  )}\n</SidePanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SidePanelDemo style={{
        height: '200px'
      }} mdxType="SidePanelDemo">
    {isOpen => <PanelContainer orientation="vertical" mdxType="PanelContainer">
        <MainPanel style={{
            background: 'linear-gradient(90deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <SidePanel fixed open={isOpen} position="right" style={{
            background: '#fff'
          }} unit="percent" width={100} mdxType="SidePanel">
          Side panel
        </SidePanel>
      </PanelContainer>}
  </SidePanelDemo>
    </Playground>
    <h2 {...{
      "id": "stackpanel"
    }}>{`StackPanel`}</h2>
    <h3 {...{
      "id": "permanent-bottom-stack-panel"
    }}>{`Permanent bottom stack panel`}</h3>
    <Playground __position={7} __code={'<PanelContainer orientation=\"horizontal\">\n  <MainPanel\n    style={{\n      background:\n        \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n      color: \'#fff\',\n    }}\n  >\n    Main panel\n  </MainPanel>\n  <PermanentStackPanel position=\"bottom\">\n    <div>Stack panel</div>\n    <div>Stack panel</div>\n    <div>Stack panel</div>\n  </PermanentStackPanel>\n</PanelContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      margin: '-40px',
      height: '200px'
    }} mdxType="Playground">
  <PanelContainer orientation="horizontal" mdxType="PanelContainer">
    <MainPanel style={{
          background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
          color: '#fff'
        }} mdxType="MainPanel">
      Main panel
    </MainPanel>
    <PermanentStackPanel position="bottom" mdxType="PermanentStackPanel">
      <div>Stack panel</div>
      <div>Stack panel</div>
      <div>Stack panel</div>
    </PermanentStackPanel>
  </PanelContainer>
    </Playground>
    <h3 {...{
      "id": "opened-bottom-stack-panel"
    }}>{`Opened bottom stack panel`}</h3>
    <Playground __position={8} __code={'<PanelContainer orientation=\"horizontal\">\n  <MainPanel\n    style={{\n      background:\n        \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n      color: \'#fff\',\n    }}\n  >\n    Main panel\n  </MainPanel>\n  <StackPanel open height={100} position=\"bottom\">\n    Stack panel\n  </StackPanel>\n</PanelContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      margin: '-40px',
      height: '200px'
    }} mdxType="Playground">
  <PanelContainer orientation="horizontal" mdxType="PanelContainer">
    <MainPanel style={{
          background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
          color: '#fff'
        }} mdxType="MainPanel">
      Main panel
    </MainPanel>
    <StackPanel open height={100} position="bottom" mdxType="StackPanel">
      Stack panel
    </StackPanel>
  </PanelContainer>
    </Playground>
    <h3 {...{
      "id": "shiftable-stack-panels"
    }}>{`Shiftable stack panels`}</h3>
    <Playground __position={9} __code={'<StackPanelDemo style={{ height: \'200px\' }}>\n  {(isOpen, panelState, setPanelState) => (\n    <PanelContainer orientation=\"horizontal\">\n      <StackPanel\n        height={50}\n        onCloseFinish={() => setPanelState(\'closed\')}\n        onCloseStart={() => setPanelState(\'closing\')}\n        onOpenFinish={() => setPanelState(\'open\')}\n        onOpenStart={() => setPanelState(\'opening\')}\n        open={isOpen}\n        position=\"top\"\n      >\n        Stack panel\n      </StackPanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        <div>Main panel</div>\n        <div>Stack panel state: {panelState}</div>\n      </MainPanel>\n      <StackPanel height={50} open={isOpen} position=\"bottom\">\n        Stack panel\n      </StackPanel>\n    </PanelContainer>\n  )}\n</StackPanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StackPanelDemo style={{
        height: '200px'
      }} mdxType="StackPanelDemo">
    {(isOpen, panelState, setPanelState) => <PanelContainer orientation="horizontal" mdxType="PanelContainer">
        <StackPanel height={50} onCloseFinish={() => setPanelState('closed')} onCloseStart={() => setPanelState('closing')} onOpenFinish={() => setPanelState('open')} onOpenStart={() => setPanelState('opening')} open={isOpen} position="top" mdxType="StackPanel">
          Stack panel
        </StackPanel>
        <MainPanel style={{
            background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          <div>Main panel</div>
          <div>Stack panel state: {panelState}</div>
        </MainPanel>
        <StackPanel height={50} open={isOpen} position="bottom" mdxType="StackPanel">
          Stack panel
        </StackPanel>
      </PanelContainer>}
  </StackPanelDemo>
    </Playground>
    <h3 {...{
      "id": "shiftable-fixed-stack-panels"
    }}>{`Shiftable fixed stack panels`}</h3>
    <Playground __position={10} __code={'<StackPanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"horizontal\">\n      <StackPanel\n        fixed\n        height={50}\n        open={isOpen}\n        position=\"bottom\"\n        style={{ background: \'#fff\' }}\n      >\n        Stack panel\n      </StackPanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <StackPanel\n        fixed\n        height={50}\n        open={isOpen}\n        position=\"top\"\n        style={{ background: \'#fff\' }}\n      >\n        Stack panel\n      </StackPanel>\n    </PanelContainer>\n  )}\n</StackPanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StackPanelDemo style={{
        height: '200px'
      }} mdxType="StackPanelDemo">
    {isOpen => <PanelContainer orientation="horizontal" mdxType="PanelContainer">
        <StackPanel fixed height={50} open={isOpen} position="bottom" style={{
            background: '#fff'
          }} mdxType="StackPanel">
          Stack panel
        </StackPanel>
        <MainPanel style={{
            background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <StackPanel fixed height={50} open={isOpen} position="top" style={{
            background: '#fff'
          }} mdxType="StackPanel">
          Stack panel
        </StackPanel>
      </PanelContainer>}
  </StackPanelDemo>
    </Playground>
    <h3 {...{
      "id": "squashable-stack-panels"
    }}>{`Squashable stack panels`}</h3>
    <Playground __position={11} __code={'<StackPanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"horizontal\">\n      <StackPanel\n        height={50}\n        open={isOpen}\n        position=\"top\"\n        transition=\"squashable\"\n      >\n        Stack panel\n      </StackPanel>\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <StackPanel\n        height={50}\n        open={isOpen}\n        position=\"bottom\"\n        transition=\"squashable\"\n      >\n        Stack panel\n      </StackPanel>\n    </PanelContainer>\n  )}\n</StackPanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StackPanelDemo style={{
        height: '200px'
      }} mdxType="StackPanelDemo">
    {isOpen => <PanelContainer orientation="horizontal" mdxType="PanelContainer">
        <StackPanel height={50} open={isOpen} position="top" transition="squashable" mdxType="StackPanel">
          Stack panel
        </StackPanel>
        <MainPanel style={{
            background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <StackPanel height={50} open={isOpen} position="bottom" transition="squashable" mdxType="StackPanel">
          Stack panel
        </StackPanel>
      </PanelContainer>}
  </StackPanelDemo>
    </Playground>
    <h3 {...{
      "id": "stack-panel-using-percentages"
    }}>{`Stack panel using percentages`}</h3>
    <Playground __position={12} __code={'<StackPanelDemo style={{ height: \'200px\' }}>\n  {isOpen => (\n    <PanelContainer orientation=\"horizontal\">\n      <MainPanel\n        style={{\n          background:\n            \'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)\',\n          color: \'#fff\',\n        }}\n      >\n        Main panel\n      </MainPanel>\n      <StackPanel\n        height={100}\n        open={isOpen}\n        position=\"bottom\"\n        transition=\"squashable\"\n        unit=\"percent\"\n      >\n        Stack panel\n      </StackPanel>\n    </PanelContainer>\n  )}\n</StackPanelDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      PermanentSidePanel,
      SidePanel,
      PermanentStackPanel,
      StackPanel,
      MainPanel,
      PanelContainer,
      SidePanelDemo,
      StackPanelDemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StackPanelDemo style={{
        height: '200px'
      }} mdxType="StackPanelDemo">
    {isOpen => <PanelContainer orientation="horizontal" mdxType="PanelContainer">
        <MainPanel style={{
            background: 'linear-gradient(180deg, rgba(244,17,80,1) 0%, rgba(255,50,50,1) 25%, rgba(248,190,7,1) 100%)',
            color: '#fff'
          }} mdxType="MainPanel">
          Main panel
        </MainPanel>
        <StackPanel height={100} open={isOpen} position="bottom" transition="squashable" unit="percent" mdxType="StackPanel">
          Stack panel
        </StackPanel>
      </PanelContainer>}
  </StackPanelDemo>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "sidepanel-1"
    }}>{`SidePanel`}</h3>
    <Props of={SidePanel} mdxType="Props" />
    <h3 {...{
      "id": "permanentsidepanel"
    }}>{`PermanentSidePanel`}</h3>
    <Props of={PermanentSidePanel} mdxType="Props" />
    <h3 {...{
      "id": "stackpanel-1"
    }}>{`StackPanel`}</h3>
    <Props of={StackPanel} mdxType="Props" />
    <h3 {...{
      "id": "permanentstackpanel"
    }}>{`PermanentStackPanel`}</h3>
    <Props of={PermanentStackPanel} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      